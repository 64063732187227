import './Introduction.css';
import MustangImage from '../assets/mustang-yellow.jpg';

const Introduction = (
    {scrollToComponent, calculatePriceRef}
) => {
    return (
        <div className="introduction-container">
            <div className="introduction-header-line">ПОДБОР И ЗАМЕНА АВТОСТЕКОЛ ОТ МАСТЕРОВ С 10-ЛЕТНИМ ОПЫТОМ</div>
            <div className="introduction-image-container">
                <img alt="mustang" src={MustangImage} />
                <button onClick={() => scrollToComponent(calculatePriceRef)} className="image-button">УЗНАТЬ ЦЕНУ</button>
            </div>
        </div>
    );
}

export default Introduction;
