import './App.css'
import Header from "./header/Header"
import Introduction from "./introduction/Introduction"
import ReasonsWhy from "./reasons-why/ReasonsWhy"
import Gallery from "./gallery/Gallery"
import CalculatePrice from "./calculate-price/CalculatePrice"
import Location from "./location/Location"
import Footer from "./footer/Footer"
import ServiceScope from "./service-scope/ServiceScope"
import {useRef} from "react";

function App() {
    const aboutUsRef = useRef(null)
    const galleryRef = useRef(null)
    const contactsRef = useRef(null)
    const calculatePriceRef = useRef(null)

    const scrollToComponent = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <div className="App">
            <Header
                scrollToComponent={scrollToComponent}
                aboutUsRef={aboutUsRef}
                galleryRef={galleryRef}
                contactsRef={contactsRef}
            />
            <Introduction scrollToComponent={scrollToComponent} calculatePriceRef={calculatePriceRef}/>
            <div ref={aboutUsRef}><ReasonsWhy/></div>
            <ServiceScope/>
            <div ref={galleryRef}><Gallery/></div>
            <div ref={calculatePriceRef}>
                <CalculatePrice/>
            </div>
            <Location/>
            <div ref={contactsRef}><Footer/></div>
        </div>
    )
}

export default App;
