import './Location.css'
import BannerImage from '../assets/location-banner.jpeg'

const Location = () => {
    const apiKey = 'AIzaSyApm630LoK4vDtk8m_OeLAe_6beUmjHnkc'
    const location = '54.31758545722959, 26.870571353893386'
    return (
        <div className="location-container">
            <div className="location-header">КАК НАС НАЙТИ:</div>
            <div className="location-map-container">
                <iframe
                    title="Google Map"
                    width="100%"
                    height="100%"
                    style={{border: 0}}
                    src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${location}`}
                    allowFullScreen
                    className="location-map"
                ></iframe>
                <img alt="map-icon" className="location-map banner-image" src={BannerImage}/>
            </div>
        </div>
    )
}

export default Location