import React, { useState } from 'react';
import './Gallery.css'; // Import your CSS file for styling
import image1 from '../assets/ph1.jpg'
import image2 from '../assets/ph2.jpg'

const Gallery = () => {
    const images = [image1, image2]
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const currentImage = images[currentImageIndex];

    const goToNextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const goToPrevImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    return (
        <div className="gallery-container">
            <h2 className="gallery-header">ГАЛЕРЕЯ РАБОТ</h2>
            <div className="image-container">
                <div className="arrow left" onClick={goToPrevImage}>{'<'}</div>
                <img src={currentImage} alt="Gallery" className="main-image" />
                <div className="arrow right" onClick={goToNextImage}>{'>'}</div>
            </div>
            <div className="preview-images">
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`Preview ${index + 1}`}
                        className={`preview-image ${index === currentImageIndex ? 'active' : ''}`}
                        onClick={() => setCurrentImageIndex(index)}
                    />
                ))}
            </div>
        </div>
    );
};

export default Gallery;
