import React, { useState } from 'react'
import './Header.css'
import Logo from '../assets/logo.png'
import MTSLogo from '../assets/mts.png'
import A1Logo from '../assets/a1.png'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import LocationOnIcon from '@mui/icons-material/LocationOn'

const Header = ({ scrollToComponent, aboutUsRef, galleryRef, contactsRef }) => {
    const [menuOpen, setMenuOpen] = useState(false)

    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    };

    const handleMenuItemClick = (ref) => {
        scrollToComponent(ref)
        setMenuOpen(false) // Close the menu after an item is clicked
    };

    return (
        <div className="header-container">
            <div className="logo-container">
                <img className="logo" alt="logo" src={Logo}/>
            </div>
            <div className={`menu ${menuOpen ? 'menu-open' : ''}`}>
                <button onClick={() => handleMenuItemClick(aboutUsRef)} className="menu-item">О НАС</button>
                <button onClick={() => handleMenuItemClick(galleryRef)} className="menu-item">ГАЛЕРЕЯ</button>
                <button onClick={() => handleMenuItemClick(contactsRef)} className="menu-item">КОНТАКТЫ</button>
            </div>
            <div className="contacts">
                <div className="contact-item"><img alt="mts" src={MTSLogo} className="contact-image"/>+375 29 7757132</div>
                <div className="contact-item"><img alt="a1" src={A1Logo} className="contact-image"/>+375 29 1361918</div>
                <div className="contact-item">
                    <LocationOnIcon sx={{fontSize: { xs: 20, md: 30 }, verticalAlign: "top"}} className="location-icon"/>ВИЛЕНСКАЯ 201И
                </div>
            </div>
            <div className="burger-menu" onClick={toggleMenu}>
                {menuOpen ? <CloseIcon fontSize="large"/> : <MenuIcon fontSize="large"/>}
            </div>
        </div>
    );
};

export default Header;
