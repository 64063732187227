import './ReasonsWhy.css'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import CarRepairIcon from '@mui/icons-material/CarRepair'


const ReasonsWhy = () => {
    return (
        <div className="reasons-why-container">
            <div className="content-wrapper">
                <div className="reasons-why-text-container">
                    <div className="reasons-why-header">ПОЧЕМУ МЫ?</div>
                    <div className="reasons-why-items">
                        <div className="reasons-why-item">
                            <CarRepairIcon sx={{fontSize: 80}}/>
                            <div>БОЛЬШЕ 10 ЛЕТ НА РЫНКЕ</div>
                        </div>
                        <div className="reasons-why-item">
                            <CheckCircleOutlineIcon sx={{fontSize: 80}}/>
                            <div>ДАЕМ МАКСИМАЛЬНУЮ ГАРАНТИЮ</div>
                        </div>
                        <div className="reasons-why-item">
                            <CurrencyExchangeIcon sx={{fontSize: 80}}/>
                            <div>СКИНЕМ ЦЕНУ, ЕСЛИ НАЙДЕТЕ ДЕШЕВЛЕ</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReasonsWhy