import './ServiceScope.css'
import ReplaceIcon from '../assets/change.jpg'
import RepairIcon from '../assets/repair.jpg'
import SaleIcon from '../assets/selling.jpg'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Pagination } from '@mui/material'
import { useMediaQuery } from '@mui/material'
import { useState, useRef, useEffect } from "react"

const ServiceScope = () => {
    const isMobile = useMediaQuery('(max-width:768px)')
    const [swiperIndex, setSwiperIndex] = useState(0)
    const swiperRef = useRef(null)

    const handlePaginationChange = (event, value) => {
        setSwiperIndex(value - 1);
        if (swiperRef.current) {
            swiperRef.current.slideTo(value - 1)
        }
    };

    const handleSwiperSlideChange = (swiper) => {
        setSwiperIndex(swiper.activeIndex)
    };

    useEffect(() => {
        if (swiperRef.current) {
            swiperRef.current.on('slideChange', handleSwiperSlideChange);
        }
    })

    return (
        <div className="scope-container">
            <div className="scope-header">ЧЕМ МЫ МОЖЕМ ПОМОЧЬ:</div>

            {/* Desktop View */}
            <div className="scope-items">
                <div className="scope-item">
                    <div className="scope-description">ЗАМЕНА</div>
                    <img src={ReplaceIcon} alt="replace" className="scope-image"/>
                </div>
                <div className="scope-item">
                    <div className="scope-description">РЕМОНТ</div>
                    <img src={RepairIcon} alt="repair" className="scope-image"/>
                </div>
                <div className="scope-item">
                    <div className="scope-description">ПРОДАЖА</div>
                    <img src={SaleIcon} alt="selling" className="scope-image"/>
                </div>
            </div>

            {isMobile && (
                <>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        className="swiper"
                        onSlideChange={handleSwiperSlideChange}
                        onSwiper={(swiper) => (swiperRef.current = swiper)}
                    >
                        <SwiperSlide className="scope-item-mobile">
                            <div className="scope-description">ЗАМЕНА</div>
                            <img src={ReplaceIcon} alt="replace" className="scope-image"/>
                        </SwiperSlide>
                        <SwiperSlide className="scope-item-mobile">
                            <div className="scope-description">РЕМОНТ</div>
                            <img src={RepairIcon} alt="repair" className="scope-image"/>
                        </SwiperSlide>
                        <SwiperSlide className="scope-item-mobile">
                            <div className="scope-description">ПРОДАЖА</div>
                            <img src={SaleIcon} alt="selling" className="scope-image"/>
                        </SwiperSlide>
                    </Swiper>
                    <Pagination
                        count={3}
                        color="primary"
                        variant="outlined"
                        page={swiperIndex + 1}
                        onChange={handlePaginationChange}
                        className="pagination"
                    />
                </>
            )}
        </div>
    );
}

export default ServiceScope;
