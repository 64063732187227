import './Footer.css'
import TelegramLogo from '../assets/telegram.png'
import ViberLogo from '../assets/viber.png'
import MTSLogo from "../assets/mts.png";
import A1Logo from "../assets/a1.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import React from "react";

const Footer = () => {
    return (
            <div className="footer-container">
                <div className="footer-subcontainer">
                    <div className="footer-header">
                        СДЕЛАЕМ ВАМ ТАК, КАК СДЕЛАЛИ БЫ СЕБЕ
                    </div>
                    <div>
                        <div className="contacts contacts-footer">
                            <div>
                                <div className="contact-item"><img alt="mts" src={MTSLogo} className="contact-image"/>+375
                                    29
                                    7757132
                                </div>
                                <div className="contact-item"><img alt="a1" src={A1Logo} className="contact-image"/>+375 29
                                    1361918
                                </div>
                                <div className="contact-item">
                                    <LocationOnIcon sx={{fontSize: {xs: 20, md: 30}, verticalAlign: "top"}}
                                                    className="location-icon"/>
                                    ВИЛЕНСКАЯ 201И
                                </div>
                            </div>
                            <div className="app-logo-links">
                                <a href="https://t.me/mv_bel" target="_blank" rel="noopener noreferrer">
                                    <img className="logo-link" alt="TelegramLogo" src={TelegramLogo}/>
                                </a>
                                <a href="viber://contact?number=%2B375297757132">
                                    <img className="logo-link" alt="ViberLogo" src={ViberLogo}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <span>ИНН692241019</span>
            </div>
    )
}

export default Footer