import React, { useState } from 'react'
import { TextField, Button } from '@mui/material'
import ScreenMustang from '../assets/screen2.png'
import './CalculatePrice.css'
import axios from 'axios'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

const CalculatePrice = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        brand: '',
        year: '',
        message: '',
    })

    const [errors, setErrors] = useState({})
    const [formSubmitted, setFormSubmitted] = useState(false)


    const validateForm = () => {
        let newErrors = {};
        if (!formData.name) newErrors.name = 'Введите имя';
        if (!formData.phone) {
            newErrors.phone = 'Введите телефон';
        } else if (!/^(?:\+375|80)\d{9}$/.test(formData.phone)) {
            newErrors.phone = 'Введите корректный номер телефона';
        }
        if (!formData.brand) newErrors.brand = 'Введите марку машины';
        if (!formData.year) {
            newErrors.year = 'Введите год выпуска';
        } else if (!/^\d{4}$/.test(formData.year) || formData.year < 1900 || formData.year > new Date().getFullYear()) {
            newErrors.year = 'Введите корректный год выпуска';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }))
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const telegramBotToken = '7017150469:AAFm5Fyq_-_ulXNLKDnH9L7i0tUK-mxBfQk'
            const telegramChatId = '702197122'
            const message = `ЗАПРОС РАССЧЕТА СТОИМОСТИ\nИмя: ${formData.name}\nТелефон: ${formData.phone}\nМарка машины: ${formData.brand}\nГод выпуска: ${formData.year}\nСообщение: ${formData.message}
            `;

            try {
                await axios.post(`https://api.telegram.org/bot${telegramBotToken}/sendMessage`, {
                    chat_id: telegramChatId,
                    text: message,
                })
                // Local development
                // await axios.post('http://localhost:3001/send-message', {
                //     telegramBotToken,
                //     telegramChatId,
                //     message,
                // })
                setFormData({
                    name: '',
                    phone: '',
                    brand: '',
                    year: '',
                    message: '',
                })
                setFormSubmitted(true)
            } catch (error) {
                console.error('Error sending message to Telegram', error);
            }
        }
    }

    return (
        <div className="container">
            <img src={ScreenMustang} alt="Chevrolet" className="image"/>
            <div className="form-container">
                {formSubmitted ? (
                    <div className="success-message">
                        <CheckCircleIcon color="success" style={{ fontSize: 60 }} />
                        <p>Ваш запрос отправлен на обработку.</p>
                        <p>Мы свяжемся с Вами как можно быстрее.</p>
                    </div>
                ) : (
                    <>
                        <h2 className="form-header">Рассчитать стоимость</h2>
                        <form className="form" onSubmit={handleSubmit}>
                            <TextField
                                label="Имя"
                                variant="outlined"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                error={!!errors.name}
                                helperText={errors.name}
                            />
                            <TextField
                                label="Телефон"
                                variant="outlined"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                error={!!errors.phone}
                                helperText={errors.phone}
                            />
                            <TextField
                                label="Марка машины"
                                variant="outlined"
                                name="brand"
                                value={formData.brand}
                                onChange={handleChange}
                                error={!!errors.brand}
                                helperText={errors.brand}
                            />
                            <TextField
                                label="Год выпуска"
                                variant="outlined"
                                name="year"
                                value={formData.year}
                                onChange={handleChange}
                                error={!!errors.year}
                                helperText={errors.year}
                            />
                            <TextField
                                label="Сообщение"
                                variant="outlined"
                                name="message"
                                multiline
                                rows={4}
                                value={formData.message}
                                onChange={handleChange}
                            />
                            <Button type="submit" variant="contained" size="large" color="success"
                                    style={{width: 'fit-content'}}>
                                Отправить
                            </Button>
                        </form>
                    </>
                )}
            </div>
        </div>
    )
}

export default CalculatePrice;
